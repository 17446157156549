export enum ApiPaths {
  LOGIN_EMAIL = 'login/email',
  POST_MY_FEED = 'post/myfeed/pages',
  POST_MY_GROUPS = 'group/page/mygroups',
  POST_JOINED_GROUPS = 'group/page/joinedgroups',
  ADMIN_REPORT = 'admin/report',
  ADMIN_REPORT_ACTION_TAKEN = 'admin/report/action-taken',
  USER_IMAGE = 'user/image',
  GET_USER = 'getUser',
  USER_POST = 'post/user',
  ME = 'me',
  ADD_EDIT_EDUCATION = 'user/education',
  USER_BANNER_MEDIA = 'user/banner',
  CREATE_POST = 'post',
  SIGNUP_EMAIL = 'signup/email',
  SIGNUP_MOBILE = 'signup/mobile',
  LOGIN_MOBILE = 'login/mobile/request',
  LOGIN_MOBILE_VERIFICATION = 'login/mobile/verify',
  SIGNUP_MOBILE_VERIFY = 'signup/mobile/verify',
  SIGNUP_EMAIL_VERIFY = 'signup/email/verify',
  SIGNUP_EMAIL_RESEND = 'signup/email/resend',
  SIGNUP_MOBILE_RESEND = 'signup/mobile/resend',
  FOGOT_EMAIL_REQUEST = 'password/forgot/request',
  RESET_PASSWORD_REQUEST = 'password/forgot/verify',
  TOPICS_CONTROLLER = 'topics',
  METADATA_CONTROLLER = 'metadata/topics',
  USER_CONTROLLER = 'user/topics',
  USER_EXPERIENCE = 'user/experience',
  UPDATE_PASSWORD = 'update/password',
  USER_BIO_UPDATE = 'user/bio',
  POST_MYFEED = 'post/myfeed',
  POST_MEDIA = 'post/media',
  USER_MEDIA = 'user/image',
  ADD_EDIT_EXPERIENCE = 'user/experience',
  POST_VOTE = 'vote',
  VOTE_USERS = 'vote/users',
  COMMENTS_POST_ID_PAGINATION = 'comments/page',
  COMMENTS = 'comment',
  FRIEND_REQUEST = 'friend/request',
  UNFRIEND = 'unfriend',
  GROUPS_FOLLOWING = 'group/following',
  FRIEND_RESPONSE = 'friend/response',
  FRIEND_PAGE_RESPONSE = 'friend/page/friends',
  INTERNAL_POST = 'internal/post',
  GROUP = 'group',
  GROUP_IMAGE = 'group/image',
  GROUP_BANNER = 'group/banner',
  FRIEND = 'friend',
  USER_PROFILE_SUMMARY = 'user/summary',
  UPDATE_USER_INTERESTS = '/user/topics',
  USER_BLOCK = 'user/block',
  USER_UN_BLOCK = 'user/unblock',
  POST_REMOVE = 'post/remove',
  USER_SEARCH = 'user/search',
  GET_ACTIVE_USER = 'user/active',
  USER_DELETE_REQUEST = '/user/delete-request',
  USER_PROFILE_FRIENDS_LIST = 'friends/list',
  USER_CAROUSEL = 'carousel'
}

export enum ApiPaths_Notification {
  NOTIFICATION_LIST = 'notification/list',
  NOTIFICATION_LIST_PAGE = 'notification/page/list',
  NOTIFICATION_READ = 'notification/read',
  NOTIFICATION_READ_ALL = 'notification/read/all',
  NOTIFICATION_DELETE = 'notification',
  NOTIFICATION_OFF = 'notification/post/configuration',
  NOTIFICATION_COUNT = 'notification/count',
  NOTIFICATION_REGISTER = 'notification/register',
  NOTIFICATION_DEREGISTER = 'notification/deregister'
}

export enum ApiPaths_Groups {
  GROUP_POST = 'group/',
  GROUP_POST_BYID = 'group/post',
  GROUP_IMAGE_MEDIA = 'group/image',
  Group_BANNER_MEDIA = 'group/banner',
  GROUP_PROFILE = 'group/profile',
  MY_GROUPS_PAGE = 'group/page/mygroups',
  GROUP_MEMBERS = 'group/members/',
  JOINED_GROUPS_PAGE = 'group/page/joinedgroups',
  GROUP_SEARCH = 'group/search',
  GROUP_USER_SEARCH = 'search',
  GROUP_JOIN = 'group/join/',
  GROUP_UNJOIN = 'group/unjoin/'
}

export enum ApiPaths_Post {
  REPORT_POST = 'user/report/post',
  REMOVE_POST = 'post/remove',
  REPOST_POST = 'repost'
}

export interface IUserSummaryDetails {
  userName: string;
  designation: string | null;
  address: string | null;
  website: string | null;
  id: string;
}
export interface IExperience {
  id?: string;
  companyName: string;
  designation: string;
  startDate: Date;
  endDate: Date;
  createdAt?: string;
  modifiedAt?: string;
}
export interface IEducation {
  id: string;
  school: string;
  degree: string;
  startDate: Date;
  endDate: Date;
  createdAt?: string;
  modifiedAt?: string;
}

export interface ITopic {
  id?: string;
  topicName?: string;
  createdAt?: string;
  modifiedAt?: string;
}
export interface IUserImage {
  id: string;
  fileName: string;
  originalFileKey: string;
  userId: string;
}

export interface IUserDetails {
  id: string;
  email: string;
  mobile: string;
  createdAt: string;
  emailVerified: boolean;
  experience: IExperience[];
  education: IEducation[] | [];
  bio: string;
  blocked: boolean;
  summary: IUserSummaryDetails | null;
  mobileVerified?: boolean;
  modifiedAt?: string | null;
  topics?: ITopic[];
  username: string | null;
  userImage?: IUserImage | null;
  userBanner?: IUserImage | null;
  postsCount: number;
  friendsCount: number;
  groupsCount: number;
}

export interface IUserSummaryRequest {
  userName: string;
  designation: string | null;
  address: string | null;
  website: string | null;
}
export interface ISaveUserEducationDetailsResponse {
  error: boolean;
  message: string | null;
  code: null;
}

export interface ISaveUserEducationDetailsRequest {
  userEducation: [
    {
      id?: string;
      school: string;
      degree: string;
      startDate: Date | undefined;
      endDate: Date | undefined;
    }
  ];
}

export interface ISaveUserExperienceDetailsRequest {
  userExperience: [
    {
      id?: string;
      designation: string;
      companyName: string;
      startDate: Date | undefined;
      endDate: Date | undefined;
    }
  ];
}

export interface IInterestTopics {
  createdAt: string | undefined;
  id: string;
  modifiedAt: string | undefined;
  name: string;
}

interface IGroupImageDto {
  fileName: string;
  groupId: string;
  id: string;
  originalFileKey?: string;
  userId?: string;
}
interface IGroupBannerDto {
  fileName: string;
  groupId: string;
  id: string;
  originalFileKey: string;
  userId: string;
}

export interface IUserPost_User_Details {
  id: string;
  username: string;
  summary: {
    userName?: string;
    designation?: string;
    address?: string;
    website?: string;
    id?: string;
  };
  userImage: {
    id: string;
    fileName?: string;
    originalFileKey?: string;
    userId: string;
  };
}

export interface IUserPost_Media {
  id: string;
  fileName?: string;
  originalFileKey?: string;
  createdBy?: string;
  postId: string;
  timestamp?: Date;
  directUrl?: string;
}

export interface IPostsDto {
  id: string;
  group: IUserPost_Group_Details;
  user: IUserPost_User_Details;
  title: string;
  description: string;
  createdAt: string;
  modifiedAt?: string;
  deleted: boolean;
  mediaDtos: IUserPost_Media[];
  numComments: number;
  numVotes: number;
  voted: boolean;
}

export interface IUserPost_Group_Details {
  id: string;
  name: string;
  description: string;
  imageURL?: string;
  media?: string;
}

export interface IPostDetailedView {
  id: string;
  name: string;
  description: string;
  createdAt?: Date | null;
  modifiedAt?: string;
  active?: boolean;
  groupImageDto?: IGroupImageDto;
  groupBannerDto: IGroupBannerDto;
  postsCount?: number;
  membersCount?: number;
  postsDto?: IPostsDto[];
}

export interface IUserSummary {
  userName: string;
  designation?: string;
  address?: string;
  website?: string;
  id?: string;
}
export interface IUser {
  id: string;
  username: string;
  imageId?: string;
  summary: IUserSummary;
}

export interface IFriendResponse {
  user: IUser;
  createdAt: string;
}

export interface IFriend {
  avatarUri: any;
  user?: any;
  id: string;
  name: string;
  avatarId?: string;
}

export interface IMemberDetailedView {
  id: string;
  username: string;
  summary: IUserSummary;
  userImage: IUserImage;
}

export interface IGroupHeader {
  name: string;
  description: string;
  groupId: string;
}
