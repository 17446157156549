import { Route, Routes } from 'react-router-dom';
import Layout from 'pages/layout';
import { Suspense, lazy } from 'react';
import {
  AuthenticateRoutes,
  UnAuthenticateRoutes
} from 'pages/layout/PrivateRoute';

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) => (
  <Suspense>
    <Component {...props} />
  </Suspense>
);

const Landing = Loadable(lazy(() => import('pages/authentication/splash')));
const Home = Loadable(lazy(() => import('pages/modules/home')));
const ViewPost = Loadable(lazy(() => import('pages/modules/home/viewPost')));
const Groups = Loadable(lazy(() => import('pages/modules/groups')));
const CreatePost = Loadable(lazy(() => import('pages/modules/create')));
const Notification = Loadable(lazy(() => import('pages/modules/notification')));
const Userprofile = Loadable(
  lazy(() => import('pages/modules/profile/userProfile'))
);
const FriendList = Loadable(
  lazy(() => import('pages/modules/friendList/friendList'))
);
const GroupPost = Loadable(
  lazy(() => import('pages/modules/groups/postGroups/groupPost'))
);
const CrateGroup = Loadable(
  lazy(() => import('pages/modules/groups/createGroup'))
);
const NotFound = Loadable(lazy(() => import('pages/modules/notFound')));

const Routers = () => (
  <>
    <Routes>
      <Route element={<UnAuthenticateRoutes />}>
        <Route path='/' element={<Landing />} />
      </Route>
      <Route element={<AuthenticateRoutes />}>
        <Route element={<Layout />}>
          <Route path='/home' element={<Home />} />
          <Route path='/post/:postId' element={<ViewPost />} />
          <Route path='/groups' element={<Groups />} />
          <Route path='/createPost' element={<CreatePost />} />
          <Route path='/profile/:userId' element={<Userprofile />} />
          <Route path='/friendlist/:userId' element={<FriendList />} />
          <Route path='/group/:groupId' element={<GroupPost />} />
          <Route path='/createGroup' element={<CrateGroup />} />
          <Route path='/notification' element={<Notification />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  </>
);

export default Routers;
