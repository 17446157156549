import { Box, CircularProgress, Link } from '@mui/material';
import GroupsSkeleton from '__mocks__/skeletonLoading/groupsLoader';
import { RoutesType } from 'models/enum';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import FriendRequest from 'pages/modules/notification/friendRequest';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppSelector } from 'store/hook';
import { colors } from 'styles/colors';

const RightSidePanel = () => {
  const navigate: any = useNavigate();
  const location = useLocation();
  const { isFriendData }: any = useAppSelector(
    (state: RootState) => state.friendList
  );

  const pathnames = location.pathname.split('/').filter((x) => x)[0];
  const [active, setActive] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (isFriendData && pathnames === RoutesType.notification) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [isFriendData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          border: `1px solid ${colors.text.primaryTheme}`,
          background: colors.background.approxAliceBlue,
          borderRadius: 2,
          mt: 3
        }}
      >
        <TypographyWithI18N
          className='smallHeaderBold'
          i18nKey={'Add Friend'}
          sx={{
            p: 2,
            color: colors.text.primaryBlack,
            borderBottom: `1px solid ${colors.border.primary}`
          }}
        />
        {!loader ? (
          <FriendRequest
            limit={-2}
            showLess={(action: boolean) => setActive(action)}
            btnShow={pathnames === RoutesType.notification}
          />
        ) : (
          <Box className='flexCenterJustify' height={150}>
            <CircularProgress size={24} />
          </Box>
        )}

        {active && pathnames !== RoutesType.notification && (
          <Link
            className='metadata'
            sx={{
              p: 2,
              color: colors.text.primaryTheme,
              display: 'flex',
              fontWeight: 500,
              cursor: 'pointer'
            }}
            underline='none'
            onClick={() =>
              navigate('notification', { state: { tabActive: 1 } })
            }
          >
            Show More
          </Link>
        )}
      </Box>

      <TypographyWithI18N
        className='metadata'
        sx={{ margin: 'auto 0 16px' }}
        i18nKey={
          'Terms of Service | Privacy Policy | Cookie Policy © 2024 4um LLC.'
        }
      />
    </Box>
  );
};

export default RightSidePanel;
