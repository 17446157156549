import React, { useEffect, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  TextField
} from '@mui/material';
import { CloseRoundIcon, SearchIcon } from 'assets';
import { colors } from 'styles/colors';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackArrowIcon } from 'assets/icon';
import { RoutesType, RoutesTypeTitle } from 'models/enum';
import AvatarImg from 'pages/components/avatarImg';
import { getUrl, groupImgUrl } from 'utilities/userImageURL';
import { axiosPostGroupSearch } from 'services/api';
import { ApiPaths_Groups } from 'models/apiPaths';
import { IUserGroupSearch } from 'models/groups';
import { scrollToPageTop } from 'utilities/helpers';
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userGroupQuery, setUserGroupQuery] = useState('');
  const [showGroupList, setShowGroupList] = useState<boolean>();
  const [userGroupList, setUserGroupList] = useState<any[]>([]);

  const pathnames = location.pathname.split('/').filter((x) => x);

  useEffect(() => {
    scrollToPageTop();
  }, [location]);

  const getTitle = (title: string) => {
    switch (title) {
      case RoutesType.home:
        return RoutesTypeTitle.home;
      case RoutesType.createGroup:
        return RoutesTypeTitle.createGroup;
      case RoutesType.createPost:
        return RoutesTypeTitle.createPost;
      case RoutesType.groupPost:
        return RoutesTypeTitle.groupPost;
      case RoutesType.groups:
        return RoutesTypeTitle.groups;
      case RoutesType.profilePage:
        return RoutesTypeTitle.profilePage;
      case RoutesType.viewPost:
        return RoutesTypeTitle.viewPost;
      case RoutesType.notification:
        return RoutesTypeTitle.notification;
      case RoutesType.friendList:
        return RoutesTypeTitle.friendlist;
      default:
        return RoutesTypeTitle.home;
    }
  };

  const userGroupItems = (items: IUserGroupSearch[]) => {
    return items.map((_itm) => {
      const userActive = Boolean(_itm.user);
      return {
        userActive: userActive,
        id: userActive ? _itm?.user?.id : _itm?.group?.id,
        name: userActive ? _itm?.user?.username : _itm?.group?.name,
        imageId: userActive
          ? _itm?.user?.userImage?.id
          : _itm?.group?.groupImageDto?.id,
        imageUrl: userActive
          ? _itm?.user?.userImage?.id
            ? getUrl(_itm?.user?.userImage?.id)
            : ''
          : _itm?.group?.groupImageDto?.id
            ? groupImgUrl(_itm?.group?.groupImageDto?.id)
            : ''
      };
    });
  };

  const handleUserGroupSearch = async (text: string) => {
    setUserGroupQuery(text);
    const payload = {
      keyword: text
      // enforceGroupMembership: true
    };
    setShowGroupList(true);
    try {
      const response = await axiosPostGroupSearch(
        ApiPaths_Groups.GROUP_USER_SEARCH,
        payload
      );
      response && setUserGroupList(userGroupItems(response));
    } catch (error) {
      console.log(error);
      setShowGroupList(false);
    }
  };

  const resetUserGroup = () => {
    setUserGroupList([]);
    setUserGroupQuery('');
    setShowGroupList(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', lg: 'row' }
        }}
      >
        <TypographyWithI18N
          className='headerTitleBold'
          i18nKey={getTitle(pathnames[0])}
          textPrefix={
            pathnames[0] !== 'home' && (
              <BackArrowIcon
                onClick={() => navigate(-1)}
                style={{
                  position: 'relative',
                  left: -10,
                  height: 20,
                  cursor: 'pointer'
                }}
              />
            )
          }
          sx={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'capitalize',
            color: colors.text.primaryBlack
          }}
        />
        <Box sx={{ position: 'relative', ml: 2 }}>
          <TextField
            placeholder='Search Individuals, Groups etc.'
            InputProps={{
              sx: {
                height: 40,
                width: 350,
                pr: 0,
                borderRadius: '30px',
                background: '#EFEFEF',
                '& fieldset': { border: 'none' }
              },
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {userGroupQuery.length > 0 && (
                    <InputAdornment
                      position='start'
                      sx={{ cursor: 'pointer' }}
                      onClick={resetUserGroup}
                    >
                      <CloseRoundIcon />
                    </InputAdornment>
                  )}
                </>
              )
            }}
            value={userGroupQuery}
            onChange={(event) => {
              handleUserGroupSearch(event.target.value);
            }}
          />
          {showGroupList && (
            <ClickAwayListener onClickAway={resetUserGroup}>
              <Paper sx={{ position: 'absolute', top: 40, width: '100%' }}>
                <MenuList sx={{ maxHeight: 250, overflowY: 'auto', px: 1.5 }}>
                  {userGroupList.length > 0 &&
                    userGroupList.map((_itm, index) => (
                      <MenuItem
                        key={index.toString()}
                        onClick={() => {
                          navigate(
                            `/${_itm?.userActive ? 'profile' : 'group'}/${_itm?.id}`
                          );
                          resetUserGroup();
                        }}
                        sx={{
                          borderTop:
                            index === 0
                              ? 0
                              : `1px solid ${colors.border.primary}`,
                          padding: 1.5
                        }}
                      >
                        <AvatarImg
                          id='userImg'
                          enableLetterImg={_itm?.imageId ? false : true}
                          alt={_itm?.name}
                          src={_itm?.imageId ? _itm.imageUrl : ''}
                          width={32}
                          height={32}
                          name={_itm?.name}
                          nameStyle={{
                            fontSize: 12
                          }}
                          style={{
                            objectFit: 'cover',
                            borderRadius: '50%'
                          }}
                        />
                        <TypographyWithI18N
                          className='bodyText'
                          i18nKey={_itm.name}
                          ml={1}
                          color={colors.text.approxNero}
                          sx={{
                            maxWidth: '85%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        />
                      </MenuItem>
                    ))}
                  {userGroupList.length === 0 && (
                    <MenuItem>No search users or groups</MenuItem>
                  )}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Header;
